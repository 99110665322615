import React from "react"

import SEO from "../components/seo"

const CaseStudy = () => (
    <>
        <SEO title="Case Study Agence Tactile" />
        <iframe
            className={"trackrecord-iframe fixed w-full h-screen inset-0 z-100 pt-24 md:p-24"}
            src="https://indd.adobe.com/view/2159e4f0-51d5-48f5-bcdb-d3b51b1f4c51?startpage=1&allowFullscreen=false"
            width="650px"
            height="460px"
            frameBorder="0"
            allowFullScreen=""
        />
    </>
)

export default CaseStudy
